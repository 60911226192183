<template>
    <v-app>
        <div class="fullContainer">

            <!-- Segment 0 -->
            <div class="Segment0">
                <div class="seg0">
                    <p class="ma-0 body-text light-grey-color-text font-weight-regular">{{ $t('Customer.MyOrders.Order_Id') }}</p>
                    <p class="ma-0 body-heading font-weight-semi-bold">{{ order.order_name }}</p>

                    <div class="ma-8"></div>

                    <p class="ma-0 body-text light-grey-color-text font-weight-regular">{{ $t('Customer.MyOrders.Order_Placed_On') }}</p>
                    <p class="ma-0 body-heading font-weight-semi-bold">{{ orderPlacedDate }}</p>
                </div>
                <div class="seg">
                    <p class="ma-0 body-text light-grey-color-text font-weight-regular">{{ $t('Customer.MyOrders.Items') }}</p>
                    <p class="ma-0 body-heading font-weight-semi-bold">{{ order.total_items - 1 }}</p>
                </div>
                <div></div>
            </div>

            <!-- Segment 1-->
            <div v-if="finalOrderStatusList && finalOrderStatusList.length > 0" class="Segment">
                <div class="orderStatusHeading">
                    <div class="d-flex flex-column align-center">
                        <h1 class="">{{ $t('Customer.MyOrders.Order_Status') }}</h1>
                        <!-- <p class="ma-0 body-text">{{ $t('Customer.MyOrders.Tracking_ID') }}: 
                            <span class="font-weight-semi-bold">{{ order.tracking_id }}</span>
                        </p> -->
                    </div>
                    <div class="d-flex align-center">
                        <v-btn class="orderStatusButton body-text" elevation="0"
                            :style="{backgroundColor: (finalOrderStatus==='Invalid Prescription' || finalOrderStatus==='Cancelled' || finalOrderStatus==='Returned') ? '#EB5757' : (finalOrderStatus==='Delivered' ? '#27AE60' :( finalOrderStatus==='Placed' ? '#1976D2':'#F89035'))}"
                        >
                            {{ finalOrderStatus }}
                        </v-btn>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="orderStatusFlow">
                    <v-timeline 
                    align-top
                    clipped
                    dense
                    style="right: 20px;"
                    >
                        <v-timeline-item
                        color="#27AE60"
                        small
                        v-for="(curr, i) in finalOrderStatusList"
                        v-if="i < finalOrderStatusList.length-1"
                        :key="i"
                        >
                            <div>
                                <p class="my-1 body-text font-weight-medium light-grey-color-text orderStatusDateTimeText">{{ order.order_status_list[i].Date }}, {{ order.order_status_list[i].Time }}</p>
                            </div>
                            <div>
                                <p class="my-0 body-heading font-weight-medium dark-grey-color-text">{{ curr }}</p>
                            </div>
                        </v-timeline-item>
                        <v-timeline-item
                        :color="(finalOrderStatus==='Invalid Prescription' || finalOrderStatus==='Returned' || finalOrderStatus==='Cancelled') ? 'red' : (finalOrderStatus==='Delivered' ? 'green' : (finalOrderStatus==='Placed' ? 'primary':'orange'))"
                        small
                        style="padding-bottom: 2%;"
                        >
                            <div>
                                <p class="my-1 body-text font-weight-medium light-grey-color-text orderStatusDateTimeText">
                                    {{ order.order_status_list[this.order.order_status_list.length-1].Date }}, {{ order.order_status_list[this.order.order_status_list.length-1].Time }}
                                </p>
                            </div>
                            <div>
                                <p  class="my-0 font-weight-medium dark-grey-color-text lastOrderStatus"
                                    :style="{color: (finalOrderStatus==='Invalid Prescription' || finalOrderStatus==='Cancelled' || finalOrderStatus==='Returned') ? '#EB5757' : (finalOrderStatus==='Delivered' ? '#27AE60' :( finalOrderStatus==='Placed' ? '#1976D2':'#F89035'))}"
                                >
                                    {{ finalOrderStatusList[this.finalOrderStatusList.length-1] }}
                                </p>
                            </div>
                        </v-timeline-item>
                    </v-timeline>
                    <div>
                        <p class="body-text prescription_comment">{{ order.prescription_comment }}</p>
                    </div>
                </div>
            </div>

            <!-- Segment 2 Upload Your Prescription -->
            <!-- <div class="Segment" v-if="order.order_status==='Invalid Prescription'"> -->
                <!-- <div class="uploadPrescriptionHeading">
                    <h1 class="">{{ $t('Customer.MyOrders.Upload_Prescription') }}</h1>
                </div>
                <div class="px-2 py-4 validPrescriptionInfo" @click="knowHow()"> -->
                    <!-- <div class="d-flex align-center"> -->
                        <!-- <p class="mx-2 my-0 body-heading font-weight-semi-bold validPrescriptionText">{{ $t('Customer.MyOrders.What_is_a_valid_prescription') }}</p>
                        <img class="mx-2 infoIcon" src="https://s3iconimages.mymedicine.com.mm/blueInfoIcon.svg" alt="info"> -->
                    <!-- </div> -->
                <!-- </div>
                <div class="uploadFromDevice light-grey-border">
                    <div class="d-flex uploadIconAndText">
                        <img class="uploadCloudIcon" src="https://s3iconimages.mymedicine.com.mm/uploadCloudIcon.svg" alt="">
                        <p class="my-0 body-text font-weight-medium uploadCloudText">{{ $t('Customer.MyOrders.Upload_file_from_your_device') }}</p>
                    </div>
                    
                    <v-btn elevation="0" class="body-text uploadButton dark-blue-border dark-blue-color-text" @click="openFileInput()">{{ $t('Customer.MyOrders.Upload') }}</v-btn>

                    <v-file-input ref="fileInput" v-model="uploadedFile" @change="handleFileUpload()" style="display: none;"></v-file-input>
                </div> -->
                <!-- CHOOSE FROM PREVIOUS PRESCRIPTIONS -->
                <!-- <div class="chooseFromPrevious light-grey-border">
                    <div class="chooseIconAndText">
                        <img class="choosePreviousPrescriptionIcon" src="https://s3iconimages.mymedicine.com.mm/choosePreviousPrescriptionIcon.svg" alt="">
                        <p class="my-0 body-text font-weight-medium choosePrevText">Choose from previous consultations</p>
                    </div>
                    
                    <v-btn elevation="0" class="body-text chooseButton">Choose</v-btn>
                </div> -->
                <!-- Uploaded Prescription -->
                <!-- <div v-if="uploadedPrescriptions && uploadedPrescriptions.length > 0" class="py-2">
                    <p class="ma-0 body-heading font-weight-semi-bold" align="left">{{ $t('Customer.MyOrders.Uploaded_Prescription') }}</p>

                    <div class="my-2 pa-4 d-flex justify-flex-start flex-wrap rounded-lg light-grey-border uploaded-prescriptions-container">
                        <div v-for="prescription, index in uploadedPrescriptions" :key="index">
                            <div class="prescription-image-container">
                                <img class="prescription-thumbnail rounded-lg light-grey-border" :src="prescription.file_url" :alt="'prescription-'+index">
                                <div class="white-background rounded-lg">
                                    <img class="thumbnail-close-icon white-background rounded-xl prescription-remove-icon" src="https://s3iconimages.mymedicine.com.mm/enclosed_close_button_red_icon.svg" alt="close-icon" @click="removePrescription(index)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- Continue Button -->
                <!-- <div class="pt-2">
                    <button 
                        class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold full-width-button text-transform-none-class" 
                        :class="[uploadedPrescriptions.length == 0 ? 'grey-background light-grey-color-text' : 'light-blue-background white-color-text']" 
                        :disabled="uploadedPrescriptions.length == 0" @click="continueAfterUploadingPrescriptions()"
                    >
                        {{ $t('Customer.MyOrders.Continue') }}
                    </button>
                </div>
                <div v-show="updatedPrescriptionsDialog">
                    <v-dialog
                        v-model="updatedPrescriptionsDialog"
                        persistent
                        max-width="290"
                        class="updatedPrescriptionsDialog"
                    >
                        <v-card>
                            <p class="body-heading font-weight-medium updatedPrescriptionsDialogText">{{ $t('Customer.MyOrders.New_Prescriptions_Uploaded') }}</p>
                            <v-btn  class="updatedPrescriptionsDialogButton text-transform-none-class body-text" 
                                @click="closeUpdatedPrescriptionsDialog()"
                            >
                                    Okay
                            </v-btn>
                        </v-card>
                        
                    </v-dialog>
                </div>
            </div> -->

            <!-- Segment 3 Products box -->
            <div class="Segment">
                <div class="segmentHeading">
                    <h1 class="">{{ $t('Customer.MyOrders.Products') }}</h1>
                </div>
                <v-divider class="divider"></v-divider>
                <div v-for="(item, i) in order.product_list">
                    <div v-if="i != order.product_list.length - 1" class="singleProduct">
                        <div class="one">
                            <img :src="item.product_image" alt="Product Image" class="productImage">
                            <div class="three">
                                <p class="ma-0 font-weight-medium body-text">{{ item.product_name }}</p>
                                <p class="ma-0 product body-small-font productQuantity">{{ $t('Customer.MyOrders.Qty') }}: {{ item.quantity }}</p>
                            </div>
                        </div>
                        <div class="two">
                            <p class="ma-0 body-text font-weight-semi-bold productPrice">
                                {{ item.price }}
                                <span>{{ item.symbol ? item.symbol : 'MMK' }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Segment 4 Billing Details -->
            <div class="Segment">
                <div class="segmentHeading">
                    <h1 class="">{{ $t('Customer.MyOrders.Billing_Details') }}</h1>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="billingList">
                    <div class="billingOne">
                        <p class="body-heading">{{ $t('Customer.MyOrders.Item_Total') }}</p>
                        <p class="body-heading">{{ $t('Customer.MyOrders.Delivery_Fee') }}</p>
                        <!-- <p class="body-heading">{{ $t('Customer.MyOrders.Tax_and_Charges') }}</p> -->
                    </div>
                    <div class="billingTwo">
                        <p class="body-heading">{{ order.billing_details.item_total + order.billing_details.tax_and_charges }} MMK</p>
                        <p class="body-heading">{{ order.billing_details.delivery_fee }} MMK</p>
                        <!-- <p class="body-heading">{{ order.billing_details.tax_and_charges }} MMK</p> -->
                    </div>
                </div>
                <v-divider class="divider2"></v-divider>
                <div class="billingList">
                    <div class="billingOne">
                        <h1 class="body-heading">{{ $t('Customer.MyOrders.Paid') }}</h1>
                    </div>
                    <div class="billingTwo">
                        <h1 class="body-heading">{{ order.billing_details.to_pay }} MMK</h1>
                    </div>
                </div>
            </div>

            <!-- Segment 5 Uploaded Prescription -->
            <!-- <div class="Segment" v-if="alreadyUploadedPrescriptionsFlag">
                <div class="segmentHeading">
                    <h1 class="">{{ $t('Customer.MyOrders.Uploaded_Prescription') }}</h1>
                </div>
                <div class="prescriptionImagesDiv">
                    <img v-for="(image, i) in prescriptionsArray" :key="i"  class="prescriptionImage" :src=image alt="Prescription">
                </div>
            </div> -->

            <!-- Segment 6 Shipping Address -->
            <div class="Segment" style="margin-bottom: 40px;">
                <div class="segmentHeading">
                    <h1 class="">{{ $t('Customer.MyOrders.Shipping_Address') }}</h1>
                </div>
                <div class="address">
                    <p class="ma-0 body-heading font-weight-medium">{{ $t('Customer.MyOrders.Deliver_to') }}: {{ order.shipping_address.deliver_to }}</p>
                    <p class="ma-0 body-text light-grey-color-text">{{ order.shipping_address.delivery_name }}</p>
                    <p v-if="order.shipping_address.phone != false" class="ma-0 body-text light-grey-color-text">{{ order.shipping_address.phone }}</p>
                    <p class="ma-0 body-text light-grey-color-text">{{ order.shipping_address.address }}</p>
                </div>
            </div>

            <!-- Segment 7 Note -->
            <!-- <div class="Segment">
                <div class="segmentHeading">
                    <h1 class="">{{ $t('Customer.MyOrders.Note') }}</h1>
                </div>
                <v-divider class="divider3"></v-divider>
                <p class="ma-0 note body-text" v-if="order.note===''">{{ $t('Customer.MyOrders.No_Note_Added') }}</p>
                <p class="ma-0 note body-text">{{ order.note }}</p>
            </div> -->

            <!-- Segment 8 Need Help -->
            <!-- Mobile -->
            <!-- <div class="Segment NeedHelpSegment">
                <h1>{{ $t('Customer.MyOrders.Get_help_with_the_order') }}</h1>
                <v-btn elevation="0" class="chatWithUsButton" @click="chatWithUs()">
                    <p class="ma-0 font-weight-medium body-heading">{{ $t('Customer.MyOrders.Chat_with_us') }}</p>
                </v-btn>
            </div> -->

            <!-- <v-divider v-if="displayButtons" class="divider4"></v-divider> -->

            <!-- <div class="lastButton">
                <v-btn elevation="0" class="returnButtton light-blue-color-text light-blue-border font-weight-medium"
                    v-if="displayButtons && canReturnProducts" @click="returnOrder()">
                    <p class="ma-0 font-weight-semi-bold body-text">{{ $t('Customer.MyOrders.Return_Products') }}</p>
                </v-btn>
                <v-btn elevation="0" class="cancelButton light-red-color-text light-blue-border font-weight-medium"
                    v-if="displayButtons && canCancelOrder" @click="cancelOrder()">
                    <p class="ma-0 font-weight-semi-bold body-text">{{ $t('Customer.MyOrders.Order_Cancellation') }}</p>
                </v-btn>
            </div> -->
            
        </div>
    </v-app>
    
</template>

<script>
import axios from 'axios';
import { axios_auth_instance_epharmacy_customer } from '../../../src/utils/axios_utils';

export default {
    name: 'Order-Details-Component-Mobile',
    props: [
        'order'
    ],
    data() {
        return {
            displayButtons: true,
            canReturnProducts: false,
            canCancelOrder: false,
            note: '',
            isDesktopMode: true,
            prescriptionsArray: [],
            uploadedPrescriptions: [],
            uploadedFile: null,
            alreadyUploadedPrescriptionsFlag: false,
            recentStatus: {},
            reuploadPrescriptionFilenames: [],
            updatedPrescriptionsDialog: false,
            orderPlacedDate: null,
            finalOrderStatusList: [],
            finalOrderStatus:''
        }
    },
    mounted() {
        window.addEventListener("resize", this.checkScreenMode);
        this.equalentOrderStatus();
        // Order Placed Date.
        let dateObj = new Date(this.order.order_placed_date);
        this.orderPlacedDate = dateObj.toLocaleString('default', { weekday: 'long' }) + ', ' + dateObj.getDate() + ' ' + dateObj.toLocaleString('default', { month: 'short' }) + ', ' + dateObj.getFullYear();

        let filenameArray = [];
        filenameArray = this.order.upload_prescriptions
        if (this.order.upload_prescriptions && this.order.upload_prescriptions.length > 0) {
            this.alreadyUploadedPrescriptionsFlag = true;

            filenameArray.forEach(image => {
                let presignedURLReadRequest = {
                    file_name: image
                };

                axios_auth_instance_epharmacy_customer.get("/cart/presignedURLRead/", { params: presignedURLReadRequest })
                .then((presignedURLReadResponse) => {
                    this.prescriptionsArray.push(presignedURLReadResponse.data.data);
                })
                .catch((presignedURLReadError) => {
                    console.log('presignedURLReadError: ', presignedURLReadError);
                });
            });
        }

        if (this.order.order_status === 'Returned' || this.order.order_status === 'Cancelled')
            this.displayButtons = false;
        
        if (this.order.order_status === 'Delivered')
            this.canReturnProducts = true;
        else
            this.canCancelOrder = true;
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkScreenMode);
        this.displayButtons= true;
        this.canReturnProducts= false;
        this.canCancelOrder= false;
    },
    methods: {
        openFileInput() {
            this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
        },
        uuidv4() {
            return 'xxxxxx#@#'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        handleFileUpload() {
            let currentFile = this.uploadedFile;

            let fileName = this.uuidv4() + currentFile.name.split(" ").join("_").toLowerCase();
            this.reuploadPrescriptionFilenames.push(fileName);

            this.uploadedPrescriptions.push({
                file_name: fileName,
                file_url: '',
                file_type: currentFile.type,
                upload_status: 'IN_PROGRESS'
            });

            let currentFileIndex = this.uploadedPrescriptions.findIndex((file) => (file.file_name === fileName));
            if (currentFile.size >= 10485760) {
                this.uploadedPrescriptions[currentFileIndex].upload_status = 'TooLarge';
            }

            let presignedURLWriteRequest = {
                file_name: fileName,
                file_type: currentFile.type,
            };
        
            axios_auth_instance_epharmacy_customer.post("/cart/presignedURLWrite", presignedURLWriteRequest).then((presignedURLWriteResponse) => {
                let fields = presignedURLWriteResponse.data.data.fields;
                let url = presignedURLWriteResponse.data.data.url;
                let post_body = {
                    ...fields,
                    'Content-Type': currentFile.type,
                    file: currentFile
                };

                const formData = new FormData();
                for(const name in post_body) {
                    formData.append(name, post_body[name]);
                }

                axios.post(url, formData).then((imgUploadSuccess) => {
                    this.uploadedPrescriptions[currentFileIndex].upload_status = 'Completed';

                    let presignedURLReadRequest = {
                        file_name: fileName
                    };
                    
                    // Presigned URL Read.
                    axios_auth_instance_epharmacy_customer.get("/cart/presignedURLRead/", { params: presignedURLReadRequest }).then((presignedURLReadResponse) => {
                        this.uploadedPrescriptions[currentFileIndex].file_url = presignedURLReadResponse.data.data;
                    }).catch((presignedURLReadError) => {
                        console.log('presignedURLReadError: ', presignedURLReadError);
                    });
                }).catch((imgUploadError) => {
                    console.log('[ERROR]: Error in image upload - ', imgUploadError);
                });
            }).catch((getPresignedURLError) => {
                console.log('[ERROR]: Error in getPresignedURL - ', getPresignedURLError);
            });
        },
        removePrescription(index) {
            this.uploadedPrescriptions.splice(index, 1);
            this.reuploadPrescriptionFilenames.splice(index, 1);
        },
        continueAfterUploadingPrescriptions() {
            // Update the Uploaded Prescription's filenames into UAT_Orders_Table

            let requestParams = {
                orderID: this.order.order_id,
                reuploadPrescriptionFilenames: this.reuploadPrescriptionFilenames
            }

            axios_auth_instance_epharmacy_customer.post("/orders/prescription", requestParams)
                .then((response) => {
                    
                })
                .catch((error) => {
                    if (error.response.status === 500) {
                        console.log('Error in ReUploading Prescriptions, Try after sometime');
                    } else {
                        console.log('[ERROR]: ', error.response.data.message);
                    }
                })

            this.updatedPrescriptionsDialog = true;
        },
        closeUpdatedPrescriptionsDialog() {
            this.updatedPrescriptionsDialog = false;
            this.$router.go();
        },
        knowHow() {
            console.log('Know How!');
        },
        downloadInvoice() {
            console.log('Invoice Downloaded!');
        },
        returnOrder() {
            console.log('Order Returned!');
        },
        cancelOrder() {
            console.log('Order Cancelled!');
        },
        chatWithUs() {
            console.log('Chat with Us');
        },
        checkScreenMode() {
            this.isDesktopMode = window.matchMedia("(min-width: 600px)").matches;
        },
        equalentOrderStatus(){
            for(let i in this.order.order_status_list){
                let orderStatus = this.order.order_status_list[i].Order_Text;
                let correctSpell = '';
                switch(orderStatus){
                    case 'placed' :
                        correctSpell = 'Placed';
                        break;
                    case 'in_process' :
                        correctSpell = 'In Process';
                        break;
                    case 'waiting' :
                        correctSpell = 'In Process';
                        break;
                    case 'postponed' :
                        correctSpell = 'In Process';
                        break;
                    case 'to_be_delivery' :
                        correctSpell = 'To Be Delivered';
                        break;
                    case 'in_delivery' :
                        correctSpell = 'On The Way';
                        break;
                    case 'delivered' : {
                        correctSpell = 'Delivered';
                        break;
                    }
                    case 'canceled' :
                        correctSpell = 'Cancelled';
                        break;
                    case 'returned' :
                        correctSpell = 'Returned';
                        break;
                }
                this.finalOrderStatusList.push(correctSpell);
            }
            this.finalOrderStatus=this.finalOrderStatusList[this.finalOrderStatusList.length-1];
        }
    }
}
</script>

<style lang="scss" scoped>
 @import '../../scss/global.scss';
 @import '../../scss/classes.scss';

 .updatedPrescriptionsDialogButton {
    width: 80px;
    margin-bottom: 20px;
    color: white;
    background-color: #48ACEF !important;
 }
 .updatedPrescriptionsDialogText {
    padding: 20px;
 }
 .updatedPrescriptionsDialog {
    
 }
 .prescription_comment {
    padding-left: 77px;
    color: #333333;
    margin-top: -10px;
 }
 .lastOrderStatus {
    font-size: 18px;
 }
 .orderStatusDateTimeText{
    color: #BDBDBD
 }
 .orderStatusFlow {
    text-align: left;
 }
 .thumbnail-close-icon {
    @media (max-width: 600px) {
        width: 24px;
        height: 24px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 24px;
        height: 24px;
    }

    @media (min-width: 1265px) {
        width: 24px;
        height: 24px;
    }
 }
 .prescription-remove-icon {
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
    z-index: 90;
}
 .prescription-image-container {
    position: relative;
 }
 .prescription-thumbnail {
    @media (max-width: 600px) {
        width: 100px;
        height: 120px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 100px;
        height: 120px;
    }

    @media (min-width: 1265px) {
        width: 100px;
        height: 120px;
    }
 }   
 .uploaded-prescriptions-container {
    width: 100%;
    gap: 16px;
}
 .uploadPrescriptionHeading {
    text-align: left;
    margin-bottom: 20px;
 }
 .prescriptionImage {
    width: 80px;
    height: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 8px;
 }
 .prescriptionImagesDiv {
    display: flex;
    flex-wrap: wrap !important;
    padding-top: 15px;
 }
 .chooseButton {
    color: #F89035;
    border: 1px solid #F89035;
    text-transform: none;
    background-color: white !important;
    margin: 0% !important;
    padding: 3% !important;
 }
 .choosePreviousPrescriptionIcon {
    width: 40px;
    height: 40px;
    margin-right: 4%;
 }
 .choosePrevText {
    width: 60%;
    text-align: left;
    color: #4F4F4F;
 }
 .chooseIconAndText {
    display: flex;
    align-items: center;
 }
 .chooseFromPrevious {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    border-radius: 8px;
}
 .uploadButton {
    text-transform: none;
    background-color: white !important;
    margin: 0% !important;
    padding: 3% !important;
 }
 .uploadCloudIcon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
 }
 .uploadCloudText {
    width: 60%;
    text-align: left;
    color: #4F4F4F;
 }
 .uploadIconAndText {
    display: flex;
    align-items: center;
 }
 .uploadFromDevice {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    padding: 2%;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
 }
 .uploadChoiceDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
 }
 .knowHowButton {
    padding: 5px !important;
    text-transform: none;
    color: white;
    background-color: #48ACEF !important;
 }
 .validPrescriptionText {
    width: 100%;
 }
 .infoIcon {
    width: 25px;
    height: 25px;
 }
 .validPrescriptionInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    background-color: #F4F6FA;
    border-radius: 8px;
    padding: 10px;
    // margin-top: 20px;
    // margin-bottom: 20px;
 }
 .lastButton {
    width: 100% !important;
    padding: 20px;
 }
 .address {
    margin-top: 20px;
    padding: 20px;
    text-align: left;
    background-color: #F4F6FA;
    border-radius: 8px;
 }
 .note {
    text-align: left;
 }
 .chatWithUsButton {
    padding: 20px !important;
    text-transform: none;
    color: white;
    background-color: #48ACEF !important;
 }
 .cancelButton {
    text-transform: none;
    background-color: white !important;
    width: 100%;
    border: 1px solid #EB5757;
 }
 .returnButtton {
    text-transform: none;
    background-color: white !important;
    width: 100%;
 }
 .billingTwo {
    text-align: right;
 }
 .billingOne {
    text-align: left;
 }
 .billingList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 }
 .productPrice {
    color: #48ACEF;
 }
 .productQuantity {
    color: #BDBDBD;
 }
 .three {
    padding-left: 24px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 .two {
    display: flex;
    flex-direction: column  ;
    justify-content: center;
 }
 .one {
    display: flex;
    flex-direction: row;
 }
 .productImage {
    height  : 60px;
    width   : 60px;
    border-radius: 4px;
 }
.singleProduct {
    // padding: 10px;
    margin-top: 20px;
    // margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.divider4 {
    border: 1px solid #E0E0E0;
    margin-top: 65px;
}
.divider3 {
    border: 1px solid #E0E0E0;
    margin-top: 12px;
    margin-bottom: 12px;
 }
 .divider2 {
    border: 1px solid #E0E0E0;
    margin-bottom: 12px;
 }
 .divider {
    border: 1px solid #E0E0E0;
    margin-top: 15px;
    margin-bottom: 15px;
 }
 .segmentHeading {
    text-align: left;
 }
 .NeedHelpSegment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
 }
 .seg0 {
    text-align: left;
 }
 .Segment0 {
    width: 90%;
    margin: 5%;
    margin-bottom: 0%;
    padding: 20px;
    background-color: #F4F6FA;
    border-radius: 4px;

    display: flex;
    justify-content: space-between;
 }
 .Segment {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    width: 90%;
    margin: 5%;
    margin-bottom: 0%;
    padding: 20px;
 }
 .orderStatusButton {
    padding-right: 10% !important;
    padding-left: 10% !important;
    margin-right: 15px !important;
    text-transform: none;
    color: white !important;
}
.orderStatusHeading {
   display: flex;
   justify-content: space-between;
}
.fullContainer {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>